@import "~monday-ui-style/dist/mixins";

.editableTypography {
  display: inline-flex;
  width: 100%;
  min-width: 0;
  // Shifts the component to align the text with the container
  margin-left: -6px;

  .input {
    display: inline-block;
    max-width: 100%;
    min-width: 64px;
    padding: 0 var(--spacing-xs);
    outline: none;
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius-small);

    &:focus,
    &:active {
      outline: none;
    }
  }

  .typograpy {
    border: 1px solid transparent;
    padding: 0 var(--spacing-xs);
    border-radius: var(--border-radius-small);

    &:hover:not(.disabled) {
      border-color: var(--ui-border-color);
    }
  }
}
