@import "../../../styles/states";

.tableRow {
  height: 40px;
  display: grid;
  grid-template-columns: var(--table-grid-template-columns);

  &[aria-selected="true"] > * {
    background-color: var(--primary-selected-color);
  }

  &:hover {
    > * {
      background-color: var(--allgrey-background-color);
    }

    &[aria-selected="true"] > * {
      background-color: var(--primary-selected-hover-color);
    }
  }
}
