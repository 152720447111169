

/* Provide sufficient contrast against white background */
/*a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}*/

.gray-text {
    color: gray;
}

.app-spirit-template-builder-add-button {
    background: var(--primary-color);
    color: var(--text-color-on-primary);
    width: 200px;
}

.app-spirit-template-builder-delete-button {
    position: absolute;
    top: 1px;
    right: 1px;
    display: none;
}

.app-spirit-template-builder-box {
    width: 800px;
    padding: 16px;
}

.app-spirit-template-builder-element {
    width: 766px;
/*    height: 60px;
    padding-left: 4px;
    padding-right: 4px;
    display: flex; 
    justify-content: center; 
    align-items: center;*/
    position: relative;
    margin-bottom: 8px;
}

    .app-spirit-template-builder-element:hover .app-spirit-template-builder-delete-button {
        display: block;
    }

.app-spirit-template-builder-element-Text {
    display: block;
    overflow-y: auto;
    max-height: 200px;
    padding-left: 4px;
    padding-right: 4px;
}

.app-spirit-template-builder-element-Table {
    justify-content: center;    
    display: flex;
}

.app-spirit-template-builder-element-Chart {
    justify-content: center;    
    display: flex;
}

.app-spirit-template-builder-element-Text p {
    margin-top: 0px;
}

.app-spirit-template-builder-element-Header {
    text-align: center; /* Center text inside the div */
}

.app-spirit-little-space-bottom {
    margin-bottom: 4px;
}

.app-spirit-textbox-margin-top {
    margin-top: 8px;
}

.app-spirit-sender-flex {
    margin-top: 8px;
    max-width: 600px;
}

.app-spirit-mapfieldname {
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
}

.app-spirit-document-table {
    max-width: 1500px;
}

.app-spirit-chart-preview-img {
    width: 100%;
    height: auto;
}

.app-spirit-chart-preview-loader {
    margin: auto;    
    position: absolute;
    top: 40px;
    left: 20px;
}

.app-spirit-important-hint {
    font-weight: bold;
    color: red;
}

.app-spirit-hidden {
    display: none;
}

.app-spirit-button-group div {
    justify-content: flex-start
}

.app-spirit-dropdown {
    width: 220px;
    margin-right: 16px;
}

.app-spirit-settings-dropdown {
    width: 350px;
}

.app-spirit-settings-div {
    margin-left: 16px;
}

.app-spirit-flex {
    margin-bottom: 8px;
}

.app-spirit-search {
    margin-top: 6px;
    margin-left: 16px;
    width: 200px;
}

.app-spirit-checkbox {    
    margin-right: 16px;
}

.app-spirit-downloadbutton {
    margin-right: 16px;
}

.app-spirit-textfield-emailaddresses {
    width: 400px;
    margin-right: 16px;
}

.app-spirit-textfield-filename {
    width: 400px;
}

.app-spirit-textfield-recipename {
    width: 400px;
}

.app-spirit-placeholder-list-box {
    padding-top: 2px !important;
}

.app-spirit-placeholder-item {
    cursor: pointer;
}

.app-spirit-cancelbutton {
    margin-left: 16px;
}

.app-spirit-label {
    /*margin-right: 16px;*/
    width: 120px;    
}

.app-spirit-boardprint-frame {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.app-spirit-tabpanels {
    padding-left: 40px;
    padding-right: 40px
}

.app-spirit-main-header {
    padding-left: 40px
}

.app-spirit-hidden {
    display: none;
}

.app-spirit-subscription-box {
    width: 390px;
    height: 260px;
    padding: 16px;
    margin-right: 32px;
    cursor: pointer;
}

.app-spirit-select-box {
    width: 390px;
    height: 400px;
    padding: 16px;
    margin-right: 32px;
    cursor: pointer;
}

    .app-spirit-select-box:hover {
        box-shadow: var(--box-shadow-medium) !important;
    }

.app-spirit-document-ready-box {
    width: 782px;
    /*height: 320px;*/
    padding: 16px;
}

.app-spirit-doc-ready-flex {
    align-items: flex-start!important;
}

.app-spirit-doc-preview-img {
    width: 300px;
    height: auto;
    cursor: pointer;
}

.app-spirit-doc-ready-text {
    min-height: 423px;
    /*Use aspect ratio of A4 1:1.41 -> 300 * 1.41*/
    border-left: 1px solid;
    border-color: var(--ui-border-color);
    padding-left: 16px;
}

.app-spirit-explanation-text-top {
    margin-top: 0px;
}

.app-spirit-explanation-text-bottom {
    margin-bottom: 0px;
}

.app-spirit-close-button {
    position: absolute; /* Or 'fixed' depending on your layout */
    top: 10px;
    right: 30px;
}

.app-spirit-placeholder-list {
    width: 300px;
}

.app-spirit-template-element-text-editor {
    flex: 1;    
}

.app-spirit-two-column {
    width: 50%;
    /*align-self: baseline;*/
    /*padding-right: 32px;*/
}

.app-spirit-chart-settings-column {
    width: 400px;
}

.app-spirit-table-settings-column {
    width: 600px;
}

.app-spirit-chart-preview-column {
    width: 100%;
    max-width: 800px;
    min-width: 400px;
}

.app-spirit-logo {
    width: 180px;
    height: auto;
    margin-top: 6px;
    margin-bottom: 12px;
    margin-left: 40px;
}

.app-spirit-tablecell-with-button {
    padding-left: 4px;
    padding-top: 4px;
}

.app-spirit-img-in-tablecell {
    width: 26px;
    height: auto;
}

.app-spirit-img-blueprint {
    /*box-shadow: 10px 10px 5px grey;*/
    border: 2px solid lightgray;
}

.app-spirit-icon-warning-in-tablecell {
    margin-left: 6px;    
}

.app-spirit-tablecell-with-warning-icon {
    /*align-items: center;*/
}

.responsive-image {
    max-width: 100%;
    height: auto;
    border: 1px solid #000000; /* Black border, 3px thick */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* Horizontal offset, vertical offset, blur radius, and shadow color */
}

body {
    font-family: var(--font-family);
    font-weight: var(--font-weight-normal);
    /*font-size: var(--font-size-subtext);*/
    font-size: 14px;
    line-height: 1.5;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

body {
    color: var(--primary-text-color); 
    background-color: var(--primary-background-color);
}

    body * {
        box-sizing: border-box;
    }

h1 {
    font-size: var(--font-size-h1);
}

h3 {
    font-size: var(--font-size-h3);
    margin-top: 14px;
    margin-bottom: 4px;
}

.right-align {
    text-align: right;
}

table {
    margin-top: 16px;
}

th, td {
    padding: 8px;
    /*border: 1px solid #ddd;*/
    text-align: left;
}

tr:nth-child(even) {
    /*background-color: #f2f2f2;*/
    background-color: var(--grey-background-color)
}

.tableDateColumn {
    width: 90px;
}

.tableNameColumn {
    width: 200px;
}